@import url("https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200..1000;1,200..1000&display=swap");

html {
  height: 100%;
  margin: 0;
}

body {
  height: 100%;
  margin: 0;
  /* Add background image */
  background-image: url("./assets/loginBgEllipse.png");
  background-size: cover; /* Make the image cover the entire viewport */
  background-repeat: no-repeat; /* Prevent the image from repeating */
  background-position: center center; /* Center the image */

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  height: 100%;
}
